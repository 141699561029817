<template>
  <div class="Company"  v-loading="loading">
    <div v-if="this.$i18n.locale=='en_us'">
      <div class="companyName">
        <div class="name" v-if="form.enName">{{form.enName}}</div>
        <div class="default" v-else>{{$t('lang.SText68')}}</div>
        <div class="Inactivated" @click="InactivatedC" v-if="form.inactivated=='0'">{{$t('lang.SText1')}}</div>
      </div>
      <div class="chineseName">
        <div class="formTitle">{{$t('lang.SText69')}} <span> ({{$t('lang.Chinese')}})</span></div>
        <div class="formData" v-if="form.companyName">{{form.companyName}}</div>
        <div class="default" v-else>{{$t('lang.SText70')}}</div>
      </div>
    </div>
    <div v-else>
      <div class="companyName">
        <div class="name" v-if="form.companyName">{{form.companyName}}</div>
        <div class="default" v-else>{{$t('lang.SText70')}}</div>
        <div class="Inactivated" @click="InactivatedC" v-if="form.inactivated=='0'">{{$t('lang.SText1')}}</div>
      </div>
      <div class="chineseName">
        <div class="formTitle">{{$t('lang.SText69')}} <span> ({{$t('lang.English')}})</span></div>
        <div class="formData" v-if="form.enName">{{form.enName}}</div>
        <div class="default" v-else>{{$t('lang.SText68')}}</div>
      </div>
    </div>
    
    <div class="Address">
      <div class="formTitle">{{$t('lang.QText50')}}</div>
      <div class="formData" v-if="form.address">
        {{form.address}},{{form.cityShow}},{{form.stateShow}},{{form.countryOrRegionShow}},{{form.zipCode}}
      </div>
      <div class="default" v-else>{{$t('lang.SText71')}}</div>
    </div>
    <div class="button">
      <el-button type="info" @click="Edit(form)" style="margin-top:25px">{{$t('lang.QText77')}}</el-button>
    </div>

    <el-dialog :visible.sync="editFlag" width="70%"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false">
      <div class="dialog-title1">
        <div class="left">
          <svg-icon icon-class="companyInfo" class="svg-icon"></svg-icon>
          {{$t('lang.SText72')}}
        </div>
        <div class="close" @click="closeDialog()">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="editInfo">
        <el-form :model="form1" :rules="rules" ref="addressForm">
          <div class="dw-card2">
            <div class="main" style="padding:0">
              <el-form-item
                class="form-item"
                prop="enName"
                :label="$t('lang.SText73')"
              >
                <el-input v-model="form1.enName" ></el-input>
              </el-form-item>
              <el-form-item
                class="form-item"
                prop="companyName"
                :label="$t('lang.SText74')"
              >
                <el-input v-model="form1.companyName" > </el-input>
              </el-form-item>
              <el-form-item
                class="form-item"
                prop="address"
                :label="$t('lang.QText50')"
              >
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  :placeholder="$t('lang.BText41')"
                  v-model="form1.address"
                  
                  :readonly="form1.lockState=='1'"
                >
                </el-input>
              </el-form-item>
              <div class="form-line">
                <el-form-item class="form-item" prop="countryOrRegion" :label="$t('lang.QText52')" >
                  <el-select
                    class="date-card-select"
                    v-model="form1.countryOrRegion"
                    :placeholder="$t('lang.BText80')"
                    @change="countryChange"
                    filterable
                    remote
                    :remote-method="findcountryOrRegionList"
                    :loading="selectLoading"
                    :disabled="form1.lockState=='1'"
                  >
                    <el-option
                      v-for="(item,index) in countryList"
                      :key="index"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="form-item" prop="state" :label="$t('lang.QText53')" >
                  <el-select
                    class="date-card-select"
                    v-model="form1.state"
                    :placeholder="$t('lang.BText80')"
                    @change="stateChange"
                    filterable
                    remote
                    :remote-method="findstateList"
                    :loading="selectLoading"
                    :disabled="form1.lockState=='1'"
                  >
                    <el-option
                      v-for="(item,index) in stateList"
                      :key="index"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="form-line">
                <el-form-item class="form-item" prop="city" :label="$t('lang.QText54')">
                  <el-select
                    class="date-card-select"
                    v-model="form1.city"
                    :placeholder="$t('lang.BText80')"
                    filterable
                    remote
                    :remote-method="findCityList"
                    :loading="selectLoading"
                    :disabled="form1.lockState=='1'"
                  >
                    <el-option
                      v-for="(item,index) in cityList"
                      :key="index"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="form-item" prop="zipCode" :label="$t('lang.QText55')">
                  <el-input v-model="form1.zipCode"  :readonly="form1.lockState=='1'"> </el-input>
                </el-form-item>
              </div>
            </div>
          </div>

          <div class="bottom-button">
            <el-button type="primary" :loading="loadSave" @click="Save('addressForm')">{{$t('lang.SText13')}}</el-button>
            <el-button type="info" @click="Cancel">{{$t('lang.QText79')}}</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import DwCheckBox from "../../../components/dwCheckBox/dwCheckBox.vue";
export default {
  components: {
    DwCheckBox,
  },
  data() {
    return {
      selectLoading: false,
      loading: false,
      companyData: {},
      editFlag: false,
      loadSave:false,
      form: {},
      form1: {},
      rules: {
        enName: [
          { required: true, message: this.$t('lang.SText178') },
          { min: 5, max: 50, message: this.$t('lang.SText172') }
        ],
        companyName: [
          { required: true, message: this.$t('lang.SText179') },
          { min: 5, max: 50, message: this.$t('lang.SText172') }
        ],
        address: [
          { required: true, message: this.$t('lang.SText180') },
          { min: 5, max: 150, message: this.$t('lang.SText173') }
        ],
        countryOrRegion: [
          { required: true, message: this.$t('lang.SText161'), trigger: 'change' }
        ],
        state: [
          { required: true, message: this.$t('lang.SText160'), trigger: 'change' }
        ],
        city: [
          { required: true, message: this.$t('lang.SText159'), trigger: 'change' }
        ],
        zipCode: [
          { required: false, message: this.$t('lang.SText177') },
          { min: 0, max: 20, message: this.$t('lang.SText181') }
        ]
      },
      countryList: [],
      cityList: [],
      stateList: [],
      userinfo:{},
      token:''
    };
  },
  created() {
    this.$session("countryList").get((value) => {
      this.countryList = value;
    });
    this.initData();
  },
  mounted: function () {
    this.getCompanyData();
  },
  computed: {
    // rules: function () {
    //   return {
    //     enName: [
    //       { required: true, message: "Please input enName", trigger: "change" },
    //     ],
    //     companyName: [
    //       {
    //         required: true,
    //         message: "Please input companyName",
    //         trigger: "change",
    //       },
    //     ],
    //     address: [
    //       {
    //         required: true,
    //         message: "this.$t('lang.SText157')",
    //         trigger: "change",
    //       },
    //     ],
    //     city: [],
    //     countryOrRegion: [
    //       {
    //         required: true,
    //         message: "Please input countryOrRegion",
    //         trigger: "change",
    //       },
    //     ],
    //     state: [
    //       {
    //         required: true,
    //         message: "Please input state",
    //         trigger: "change",
    //       },
    //     ],
    //   };
    // },
  },
  methods: {
    initData: function () {
      this.$session("userInfo").get((value) => {
        this.userinfo = value;
      });
      this.$session("token").get((value) => {
        this.token = value;
      });
    },
    InactivatedC(){
      let that = this;
      let data = {
        companyId: this.userinfo.companyid,
        // id:"1481550332951117824"
      };
      that.$ajax
        .cPost(that.source.basCompany_api.verification,data)
        .then((res) => {
          if (res.data.result=='true') {
            that.$notify({
              title: res.data.message,
              type: "success",
              offset: 100,
              duration: 3000,
            });
          } else {
            that.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    getCompanyData() {
      this.loading=true
      let that = this;
      let data = {
        companyId: that.userinfo.companyid,
        // companyId:"1481550332951117824"
      };
      that.$ajax
        .cPost(that.source.basCompany_api.list,data)
        .then((res) => {
          if (res.data.result=='true') {
            that.loading=false
            that.form = res.data.list?res.data.list:{};
          } else {
            that.loading=false
            that.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    Save(formName){
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.loadSave=true
          let data = {
            id:that.form1.id,
            companyName:that.form1.companyName,
            enName:that.form1.enName,
            address:that.form1.address,
            city:that.form1.city,
            state:that.form1.state,
            zipCode:that.form1.zipCode,
            countryOrRegion:that.form1.countryOrRegion,
            addressId:that.form1.addressId,
            companyId:that.form1.id
          };
          that.$ajax
            .cPost(that.source.basCompany_api.edit, data)
            .then((res) => {
              if (res.data.result) {
                that.editFlag=false
                that.loadSave=false
                that.getCompanyData()
                that.$notify({
                  title: res.data.message,
                  type: "success",
                  offset: 100,
                  duration: 3000,
                });
              } else {
                that.editFlag=true
                that.loadSave=false
                that.$notify.error({
                  title: res.data.message,
                  offset: 100,
                  duration: 3000,
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      
    },
    Cancel(){
      this.editFlag = false;
    },
    closeDialog() {
      this.editFlag = false;
    },
    Edit(val) {
      this.editFlag = true;
      this.$set(this.form1,'address',val.address)
      this.$set(this.form1,'addressId',val.addressId)
      this.$set(this.form1,'city',val.city)
      this.$set(this.form1,'companyName',val.companyName)
      this.$set(this.form1,'countryOrRegion',val.countryOrRegion)
      this.$set(this.form1,'enName',val.enName)
      this.$set(this.form1,'id',val.id)
      this.$set(this.form1,'inactivated',val.inactivated)
      this.$set(this.form1,'isActivation',val.isActivation)
      this.$set(this.form1,'mySelfCountry',val.mySelfCountry)
      this.$set(this.form1,'state',val.state)
      this.$set(this.form1,'zipCode',val.zipCode)
      this.$set(this.form1,'lockState',val.lockState)
      this.getStateList({ code: val.countryOrRegion })
      this.getCityList({ state: val.state, code: val.countryOrRegion })
      // 清除表单校验的提示
      if (this.$refs['addressForm']) {
      // 延时执行
        this.$nextTick(function () {
          this.$refs['addressForm'].clearValidate();
        })
      };
    },
    getStateList(data) {
      let that = this;
      that.$ajax
        .cPost(that.source.address_api.getStateList, data)
        .then((res) => {
          if (res.data.result) {
            that.stateList = res.data.data;
          } else {
            that.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    getCityList(data) {
      let that = this;
      that.$ajax
        .cPost(that.source.address_api.getCityList, data)
        .then((res) => {
          if (res.data.result) {
            that.cityList = res.data.data;
          } else {
            that.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    countryChange(e) {
      console.log(e);
      this.$set(this.form1,'state',"")
      this.$set(this.form1,'city',"")
      if (e) {
        this.getStateList({ code: e });
      }
    },
    stateChange(e) {
      console.log(e);
      this.$set(this.form1,'state',e)
      this.$set(this.form1,'city',"")
      if (e) {
        this.getCityList({ state: e, code: this.form1.countryOrRegion });
      }
    },
    findcountryOrRegionList(e) {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.address_api.getCountryList, {name:e})
          .then((res) => {
              console.log(res);
              this.selectLoading = false;
              if ((res.data.result = "true")) {
                this.countryList = res.data.data;
              } else {
                this.$notify.error({
                  title: res.data.message,
                  offset: 100,
                  duration: 3000,
                });
              }
            });
    },
    findstateList(e) {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.address_api.getStateList, {name:e,code:this.form1.countryOrRegion})
          .then((res) => {
              console.log(res);
              this.selectLoading = false;
              if ((res.data.result = "true")) {
                this.stateList = res.data.data;
              } else {
                this.$notify.error({
                  title: res.data.message,
                  offset: 100,
                  duration: 3000,
                });
              }
            });
    },
    findCityList(e) {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.address_api.getCityList, { name:e,state: this.form1.state, code: this.form1.countryOrRegion })
          .then((res) => {
              console.log(res);
              this.selectLoading = false;
              if ((res.data.result = "true")) {
                this.cityList = res.data.data;
              } else {
                this.$notify.error({
                  title: res.data.message,
                  offset: 100,
                  duration: 3000,
                });
              }
            });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.Company {
  width: 900px;
  text-align: left;
  margin-left: 150px;
  min-height: 600px;
  .form-line .el-form-item{
    width: 50%;
    float: left;
    .el-form-item__label,.el-select{
      width: 98%;
    }
  }
  .el-dialog {
    margin-top: 0 !important;
    min-height: 100%;
    margin: 0 auto;
    .el-dialog__header {
      padding: 0;
      display: none;
    }

    .el-dialog__body {
      padding: 0;
      min-height: 100%;
      // overflow: auto;
    }
  }
  .companyName {
    margin-bottom: 60px;
    .default {
      font-size: 36px;
      color: $fontColor3;
    }
    .name {
      font-size: 36px;
      color: $mainColor;    line-height: 50px;
    }
    .Inactivated {
      font-size: 14px;
      color: $fontColor4;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .chineseName,
  .Address {
    margin-bottom: 40px;
    .default {
      font-size: 30px;
      color: $borderColor1;
    }
    .formTitle {
      font-size: 20px;
      color: $fontColor3;
      line-height: 24px;
      span{
        font-size: 12px;
      }
    }
    .formData {
      font-size: 30px;
      color: $mainColor;
      line-height: 40px;
    }
  }
  .kDialog {
    .el-select {
      width: 100%;
    }
    .el-input__inner {
      height: 48px;
      width: 100%;
    }
  }
  .editInfo {
    margin-top: 30px;
    padding: 0 200px;
  }
}
</style>